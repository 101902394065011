export const AUTHENTICATED_CHILDREN = [
    {path: "/", class: "dashboard", element: "LoadableDashboard", menuLess: true},
    {path: "profile", class: "user-profile", element: "LoadableUserProfile"},
    {path: "notifications", class: "notification", element: "LoadableNotifications"},
    {path: "settings", class: "settings", element: "LoadableSettings"}
];

const ROUTES = [
    {path: "/", class: "landing", headerClasses: "container position-absolute l-0 r-0 bg-black", element: "LoadableLogin", menuLess: true, drawerLess: true, noTools: true, concealHeaderHeight: true},
    {path: "/login", class: "login", headerClasses: "container position-absolute l-0 r-0 bg-black", element: "LoadableLogin", menuLess: true, drawerLess: true, noTools: true, concealHeaderHeight: true},
    {path: "/join", class: "join", headerClasses: "container position-absolute l-0 r-0 bg-black", element: "LoadableJoin", menuLess: true, drawerLess: true, noTools: true, concealHeaderHeight: true, noSearch: true, displaySignInPrompt: true},
    {path: "/:user/*", element: "LoadableAuthenticator", children: AUTHENTICATED_CHILDREN},
];

export default ROUTES;