import Immutable from "immutable"
import {combineReducers} from "redux"
import { DISPATCH_CONTENT, DISPATCH_LEVELS, DISPATCH_MODAL, DISPATCH_NOTIFICATION, DISPATCH_TOGGLES, DISPATCH_TOOLTIP,
    DISPATCH_USER_DETAILS, IS_MOBILE, SET_LOGGED_IN, TOGGLE_LOADER } from "./global-actions";

const initialState = Immutable.fromJS(
    {
        modal: {meta: {}}, content: {icons: {}},
        tooltip: {show: false},
        toggles: [],
        logs: []
    })

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISPATCH_CONTENT:
        case DISPATCH_LEVELS:
        case DISPATCH_MODAL:
        case DISPATCH_NOTIFICATION:
        case DISPATCH_TOGGLES:
        case DISPATCH_TOOLTIP:
        case DISPATCH_USER_DETAILS:
        case SET_LOGGED_IN:
        case TOGGLE_LOADER:
        case IS_MOBILE:
            return state.mergeDeep(action.payload)
        default:
            return state
    }
}

export default combineReducers({
    data: combineReducers({
        global: globalReducer,
        pages: combineReducers({
        })
    })
})
