import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { connect, Provider } from "react-redux"
import { matchPath, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loadable from "react-loadable"
import { dispatchDeviceType } from "./global-actions";
import { PageLoader } from "./components";
import Helper from "./utils/Helper";
import ROUTES from "./routes";

const LoadableLogin = Loadable({
    loader: () => import("./pages/Login"),
    loading: PageLoader
})

const LoadableJoin = Loadable({
    loader: () => import("./pages/Join"),
    loading: PageLoader
})

const loadables = {
    LoadableLogin,
    LoadableJoin,
}

const Router = props => {
    const {dispatchDeviceType, store} = props;
    const [matchedRoute, setMatchedRoute] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let matchedRoute = ROUTES.find(route => matchPath({...route, caseSensitive: false, end: true}, location.pathname));
        if (matchedRoute && matchedRoute.children) {
            matchedRoute = matchedRoute.children.find(route => {
                const checkPath = matchedRoute.path.replace("*", route.path);
                return matchPath({...route, path: checkPath, caseSensitive: false, end: true}, location.pathname)
            });
        }
        setMatchedRoute(matchedRoute);
    }, [location]);

    useEffect(() => {
        dispatchDeviceType({ isMobile: Helper.isMobile() });
    }, [dispatchDeviceType]);

    const getRoutes = () => {
        const routeRenders = ROUTES.map((route, i) => {
            const Component = loadables[route.element];
            return <Route key={"router-route-" + i} path={route.path} element={
                <Component
                    navigate={navigate}
                />}
            />
        });
        return <Routes>{routeRenders}</Routes>;
    };

    const rootClass = matchedRoute && matchedRoute.class ? " " + (matchedRoute.class.indexOf(" ") > -1 ? matchedRoute.class.split(" ")[0] : matchedRoute.class) : ""
    return  <Provider store={store}>
                <div className={`c-Router__global-events-interceptor h-100 w-100${rootClass}`}>
                    <div className="c-Router__app-container overflow-hidden h-100 w-100 d-flex flex-column">
                        <div className="c-Router__main-content d-flex h-100 w-100 overflow-auto">
                            {getRoutes()}
                        </div>
                    </div>
                </div>
            </Provider>;
};

Router.propTypes = {
    store: PropTypes.object,
    dispatchDeviceType: PropTypes.func,
    dispatchHideTooltips: PropTypes.func
}

const mapDispatchToProps = {
    dispatchDeviceType
}

export default connect(null, mapDispatchToProps)(Router);
