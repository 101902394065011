import React from 'react';
import './PageLoader.component.scss';

const PageLoader = () => {
  return (
    <div className="c-PageLoader">
      <div className="c-PageLoader__lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
};

export default PageLoader;